import { addData } from "@/api/api";
export default {
  name: "index",
  props: ['Row'],
  data() {
    return {
      token: localStorage.getItem('token'),
      Info: {
        id: '',
        name: '',
        no: '',
        show_top: ''
      },
      gradeList: []
    };
  },
  mounted() {},
  methods: {
    closeDiv() {
      this.$emit('closeDiv');
    },
    getInfo(row) {
      //清空表单
      if (row) {
        this.Info = JSON.parse(row);
      } else {
        this.Info = {};
      }
    },
    subData() {
      //提交
      if (!this.Info.no || !this.Info.name) {
        this.$root.ElMessage.error('编号和姓名不能为空！');
        return false;
      }
      addData(this.Info, 'School').then(res => {
        if (res.success) {
          this.$root.ElMessage({
            type: 'success',
            message: res.msg
          });
          this.closeDiv();
          this.$emit('getList');
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    },
    handleAvatarSuccess(e) {
      //图片上传成功后
      this.Info.businessLicenseImg = e.data;
    }
  }
};